<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasTextImage = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasTextImage;
  blockPosition: number;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("articleHeader");

const { getConfigValue } = useCmsElementConfig(slot);
const title: string = getConfigValue("title");
const subtitle: string = getConfigValue("subtitle");
const textStyle: "magazine" | "default" = getConfigValue("textStyle");
const color: "dark" | "light" = getConfigValue("color");
const backgroundColor: string = getConfigValue("backgroundColor");
const media: Object = getConfigValue("media");
</script>

<template>
  <div class="s-gutter" :class="{
    'lg:pr-0': media
  }" :data-pos="blockPosition">
    <div class="relative c-width-narrow">
      <div
        class="w-full"
        :class="{
          'lg:w-[60%] pt-10 lg:pt-40 sm:pb-8 lg:pb-20': media,
          'c-width-small py-10': !media,
          'lg:pt-12': !media && textStyle !== 'magazine',
          'lg:pt-30': !media && textStyle === 'magazine',
        }"
      >
        <h1
          class="leading-tight text-balance"
          :class="{
            'text-stage-medium': textStyle !== 'magazine',
            'text-stage-large font-serifDisplay': textStyle === 'magazine',
            'text-maas-typography-headline-primary': color === 'dark',
            'text-maas-typography-headline-light': color === 'light',
          }"
        >
          {{ title }}
        </h1>

        <SharedCmsImage
          v-if="media"
          :src="media?.url"
          :crop="media?.crop"
          :focus-point="media?.focusPoint"
          :alt="title"
          width="760"
          class-img="mt-8 lg:mt-auto aspect-2/3 lg:absolute sm:z--1 lg:top-0 lg:top--30 lg:right-0 lg:max-w-[50%]"
          :loading="blockPosition === 0 ? 'eager' : 'lazy'"
        />

        <h3
          v-if="subtitle"
          class="last:mt-10 text-h4 lg:text-h3 font-normal"
          :class="{
            'last:mt-4 lg:mt-12': textStyle !== 'magazine',
            'last:mt-10 lg:mt-20': textStyle === 'magazine',
          }"
        >
          {{ subtitle }}
        </h3>
      </div>

    </div>
  </div>
</template>
